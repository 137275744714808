import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import '../global.css'
import favicon from '../favicon.png'
import Nav from '../navigation.js'
import Footer from '../footer.js'
import aboutBg from '../aboutBg.jpg'
import lucPhoto from '../photoLuc.png'
import fritzPhoto from '../photoFritz.png'
import splendoTeam from '../splendoTeam.png'

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`

const Hero = styled.header`
  text-align: center;
  position: relative;
  height: 50%;
  background-image: url(${aboutBg});
  background-size: cover;
  background-position: bottom;
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    h1 {
      font-size: 3em;
      @media (max-width: 800px) {
        font-size: 2.4em;
      }
    }
    span {
      font-family: nunitoReg, sans-serif;
      width: 90%;
      display: inline-block;
      margin-top: 10px;
      font-size: 1.2em;
      @media (max-width: 800px) {
        font-size: 1.1em;
        width: 80%;
      }
    }
  }
`

const Bios = styled.div`
  .bioSection {
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    width: 100%;
    padding: 40px 0;
    margin: 0 auto;
    max-width: 1200px;
    @media (max-width: 800px) {
      width: 80%;
    }
    div {
      flex: 0 100%;
      padding: 30px;
      height: 700px;
      align-items: flex-start;
      @media (max-width: 800px) {
        padding: 40px 0;
        height: inherit;
      }
    }
    @media (max-width: 800px) {
      flex-wrap: wrap;
    }
    img {
      width: 100%;
      max-width: 300px;
    }
    h2 {
      padding: 20px 0;
      font-size: 2em;
    }
    span {
      display: inline-block;
      height: 200px;
      font-family: nunitoSemi, sans-serif;
      line-height: 24px;
      opacity: 0.7;
      @media (max-width: 800px) {
        height: inherit;
      }
    }
  }
`

export default () => (
  <Container>
    <Helmet>
      <title>Splendo</title>
      <meta charSet="utf-8" />
      <meta name="description" content="Splendo" />
      <link rel="icon" type="image/png" href={favicon} sizes="50x50" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    </Helmet>

    <Nav />

    <Hero>
      <div className="wrapper">
        <h1>About Us</h1>
        <span>Splendo is a privately held company founded by Luc Demarteau.</span>
      </div>
    </Hero>

    <Bios>
      <div className="bioSection">
        <div>
          <img src={lucPhoto} alt="Luc" />
          <h2>Luc</h2>
          <span>
            Luc is as founder and creative mind of Splendo the driving force behind several innovative technologies. Luc is able to motivate his
            employees to develop cutting edge technologies for several industries. Moreover, he coordinates these innovative projects with a
            multidisciplinary vision. Currently AI has his primary focus. Luc holds a master of science degree in business administration from Erasmus
            University Rotterdam.
          </span>
        </div>

        <div>
          <img src={splendoTeam} alt="Team" />
          <h2>Team Splendo</h2>
          <span>
            Team Splendo consists of top-notch developers who strive to improve their skills and knowledge every day. Thanks to many years of
            experience in the area of mobile apps, IoT and AI, several valuable expertises have been developed within Splendo. All Splendo developers
            have a bachelor or master degree in computer science, physics or mathematics.
          </span>
        </div>

        <div>
          <img src={fritzPhoto} alt="Frits" />
          <h2>Frits</h2>
          <span>
            Frits is our company dog and the life of our office. A true coding companion, join us to enjoy walking him as much as you'd like!
          </span>
        </div>
      </div>
    </Bios>

    <Footer />
  </Container>
)
